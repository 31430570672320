import * as React from 'react';

import '../layout.css';
import '../../styles/finish.css';
import '../../styles/global.css';

const FinishFive = props => {
  return (
    <div className="containerFinish">
      <div className="content">
        {/* <div className="top">
          <div className="top_img" />
        </div> */}
        <div className="center">
          <div className="img_five" />
        </div>
        <h1 className="title_finish">Era Informatica</h1>
        <p className="description">
          Sua empresa já passou por tantas transformações e agora está se
          digitalizando. Conte com a GO.K para ajudarmos em sua estratégia.
        </p>
        <h2 class="label">Nós vamos até você!</h2>
        <a className="btn-link" target="_blank" href="https://gok.digital/">
          <button className="btn-body btn-primary">
            Conheça a GOK
          </button>
        </a>
      </div>
    </div>
  );
}

export default FinishFive;
