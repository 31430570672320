import * as React from 'react';

import '../layout.css';
import '../../styles/finish.css';
import '../../styles/global.css';

const FinishThree = props => {
  return (
    <div className="containerFinish">
      <div className="content">
        {/* <div className="top">
          <div className="top_img" />
        </div> */}
        <div className="center">
          <div className="img_three" />
        </div>
        <h1 className="title_finish">{`Revolução\nIndustrial`}</h1>
        <p className="description">
          Sua empresa está em linha de produção para atender o usuário.
          Mas e a experiência? Como você mede ela hoje?
        </p>
        <h2 class="label">Nós vamos até você!</h2>
        <a className="btn-link" target="_blank" href="https://gok.digital/">
          <button className="btn-body btn-primary">
            Conheça a GOK
          </button>
        </a>
      </div>
    </div>
  );
}

export default FinishThree;
