import * as React from 'react';

import '../layout.css';
import '../../styles/finish.css';
import '../../styles/global.css';

const FinishFour = props => {
  return (
    <div className="containerFinish">
      <div className="content">
        {/* <div className="top">
          <div className="top_img" />
        </div> */}
        <div className="center">
          <div className="img_four" />
        </div>
        <h1 className="title_finish">Apollo 11</h1>
        <p className="description">
          Sua empresa quer chegar onde nenhuma outra chegou, mas para isso
          é importante ter ajuda de especialistas no assunto.
        </p>
        <h2 class="label">Nós vamos até você!</h2>
        <a className="btn-link" target="_blank" href="https://gok.digital/">
          <button className="btn-body btn-primary">
            Conheça a GOK
          </button>
        </a>
      </div>
    </div>
  );
}

export default FinishFour;
