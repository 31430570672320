import * as React from 'react';

import '../layout.css';
import '../../styles/finish.css';
import '../../styles/global.css';

const FinishSix = props => {
  return (
    <div className="containerFinish">
      <div className="content">
        {/* <div className="top">
          <div className="top_img" />
        </div> */}
        <div className="center">
          <div className="img_six" />
        </div>
        <h1 className="title_finish">Era dos dados</h1>
        <p className="description">
          Sua empresa está em uma nova era, e a GO.K pode ajudá-lo a
          continuar escalando o seu negócio.
        </p>
        <h2 class="label">Nós vamos até você!</h2>
        <a className="btn-link" target="_blank" href="https://gok.digital/">
          <button className="btn-body btn-primary">
            Conheça a GOK
          </button>
        </a>
      </div>
    </div>
  );
}

export default FinishSix;
