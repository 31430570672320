import * as React from "react";
import firebase from 'firebase/app'
import 'firebase/database'

import { connect } from 'react-redux';

// Components
import {
  FinishOne,
  FinishTwo,
  FinishThree,
  FinishFour,
  FinishFive,
  FinishSix
} from '../components';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID
};

const Finish = (props) => {
  const countYes = props.questions.filter(value => value === true);
  const countNo = props.questions.filter(value => value === false);

  React.useEffect(() => {
    if (!firebase) {
      firebase.initializeApp(config);
      return
    }

    const id = '_' + Math.random().toString(36).substr(2, 9);
    const email = props.email;

    firebase
      .database()
      .ref("answers/" + id)
      .set({
        email: email,
        respostas: {
          1: props.questions[0],
          2: props.questions[1],
          3: props.questions[2],
          4: props.questions[3],
          5: props.questions[4]
        }
      });
  }, [firebase]);

  const _renderResponse = () => {
    if (countNo.length === 5) {
      return <FinishOne />;
    } else if (countYes.length === 1) {
      return <FinishTwo />;
    } else if (countYes.length === 2) {
      return <FinishThree />;
    } else if (countYes.length === 3) {
      return <FinishFour />;
    } else if (countYes.length === 4) {
      return <FinishFive />;
    } else {
      return <FinishSix />;
    }
  }

  return _renderResponse();
}

const mapStateToProps = state => ({
  questions: state.response,
  email: state.email
});

export default connect(
  mapStateToProps,
  {}
)(Finish);
